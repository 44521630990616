<template>
  <b-card-code>
    <div class="row">
      <div class="col-12 row align-items-center">
        <div class="col-lg-5">
          <button class="btn btn-primary" v-b-modal.filter-modal>Szűrés</button>
          <span class="ml-1 badge badge-primary" v-show="monolit !== '%'">
            <span v-if="monolit == 'ME%'">
              Monolit Épszer Kft.
              <feather-icon
                style="cursor:pointer"
                @click="removeFilter('company')"
                icon="MinusCircleIcon"
                size="26"
                class="text-light"
              />
            </span>
            <span v-else>
              Monolit Profi Kft.
              <feather-icon
                style="cursor:pointer"
                @click="removeFilter('company')"
                icon="MinusCircleIcon"
                size="26"
                class="text-light"
              />
            </span>
          </span>
          <span class="ml-1 badge badge-primary" v-show="archived !== '%'">
            <span v-if="archived == '1'">
              Archiváltak
              <feather-icon
                style="cursor:pointer"
                @click="removeFilter('archived')"
                icon="MinusCircleIcon"
                size="26"
                class="text-light"
              />
            </span>
            <span v-else>
              Aktívak
              <feather-icon
                style="cursor:pointer"
                @click="removeFilter('archived')"
                icon="MinusCircleIcon"
                size="26"
                class="text-light"
              />
            </span>
          </span>
          <span class="ml-1 badge badge-primary" v-show="project !== '%'">
            <span>{{ returnProjectbyID(project)}}
              <feather-icon
                style="cursor:pointer"
                @click="removeFilter('project_id')"
                icon="MinusCircleIcon"
                size="26"
                class="text-light"
              />
            </span>
          </span>
        </div>
        <div class="col-lg-3">
          <h3>
            <feather-icon
              icon="ChevronLeftIcon"
              class="mr-25 font-medium-5"
              @click="yearMin()"
            />
            {{ year }}
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-25 font-medium-5"
              @click="yearPlus()"
            />
          </h3>
        </div>
        <div class="col-lg-4">
          <p class="text-right">
            Hátralévő összesen: <span class="badge badge-primary">{{ parseInt(report.remaining_total).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</span>
          </p>
          <p class="text-right mb-0">
            {{ year }} számlázott összesen: <span class="badge badge-primary">{{ parseInt(report.total_invoice).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</span>
          </p>

        </div>
        <div class="col-lg-12 row scrollable-box" v-if="year === currentYear">
          <div class="col-lg-12" v-for="(project_id, index_main) in report.project_ids" :key="index_main" v-if="!(report.projects[index_main].archive && report.year[index_main] === 0)">
            <hr>
            <div class="row">
              <div class="mb-0 mr-3">Projekt neve: <b>{{ report.projects[index_main].name }}</b></div>
              <div class="mb-0 mr-3">Kezdés dátuma: <b>{{ report.projects[index_main].start_date }}</b></div>
              <div class="mb-0 mr-3">Várható befejezés: <b>{{ report.projects[index_main].end_date }}</b></div>
              <div class="mb-0 mr-3">Munkaszám: <b>{{ report.projects[index_main].work_number }}</b></div>
              <div>Státusz: <b v-if="report.projects[index_main].archive"><span class="badge badge-success">Lezárt</span></b> <b v-else><span class="badge badge-warning">Folyamatban</span></b></div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-3 text-center">
                <span class="badge badge-primary">Vállalási összeg: <b>{{ parseInt(report.project_price[index_main]).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</b></span>
              </div>
              <div class="col-lg-3 text-center">
                <span class="badge badge-info">{{ (year-1) }} számlázott: <b>{{ parseInt(report.total_last_year[index_main]).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</b></span>
              </div>
              <div class="col-lg-3 text-center">
                <span class="badge badge-success">{{ year }} számlázott: <b>{{ parseInt(report.year[index_main]).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</b></span>
              </div>
              <div class="col-lg-3 text-center">
                <span class="badge badge-danger">Hátralék: <b>{{ parseInt(report.remaining[index_main]).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</b></span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-1">
                Január
              </div>
              <div class="col-lg-1">
                Február
              </div>
              <div class="col-lg-1">
                Március
              </div>
              <div class="col-lg-1">
                Április
              </div>
              <div class="col-lg-1">
                Május
              </div>
              <div class="col-lg-1">
                Június
              </div>
              <div class="col-lg-1">
                Július
              </div>
              <div class="col-lg-1">
                Augusztus
              </div>
              <div class="col-lg-1">
                Szeptember
              </div>
              <div class="col-lg-1">
                Október
              </div>
              <div class="col-lg-1">
                November
              </div>
              <div class="col-lg-1">
                December
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-lg-1" :class="[report.projects[index_main].archive ? 'bg-success-light': null ]" v-for="index in 12" :key="index">
                <small :id="'popover'+index+index_main+report.months[index_main][index]">{{ parseInt(report.months[index_main][index]).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</small>
                <b-popover :target="'popover'+index+index_main+report.months[index_main][index]" triggers="hover" placement="top">
                  <template #title>Havi számlák</template>
                  <p class="pb-0 mb-0 text-right" v-for="(price, index2) in report.months_array[index_main][index]" :key="index2"><span v-if="price != null"><b>{{report.months_array_names[index_main][index][index2]}}:</b> {{parseInt(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} Ft</span></p>
                </b-popover>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 row scrollable-box" v-else>
          <div class="col-lg-12" v-for="(project_id, index_main) in report.project_ids" :key="index_main" v-show="report.year[index_main]>0">
            <hr>
            <div class="row">
              <div class="mb-0 mr-3">Projekt neve: <b>{{ report.projects[index_main].name }}</b></div>
              <div class="mb-0 mr-3">Kezdés dátuma: <b>{{ report.projects[index_main].start_date }}</b></div>
              <div class="mb-0 mr-3">Várható befejezés: <b>{{ report.projects[index_main].end_date }}</b></div>
              <div class="mb-0 mr-3">Munkaszám: <b>{{ report.projects[index_main].work_number }}</b></div>
              <div>Státusz: <b v-if="report.projects[index_main].archive"><span class="badge badge-success">Lezárt</span></b> <b v-else><span class="badge badge-warning">Folyamatban</span></b></div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-3 text-center">
                <span class="badge badge-primary">Vállalási összeg: <b>{{ parseInt(report.project_price[index_main]).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</b></span>
              </div>
              <div class="col-lg-3 text-center">
                <span class="badge badge-info">{{ (year-1) }} számlázott: <b>{{ parseInt(report.total_last_year[index_main]).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</b></span>
              </div>
              <div class="col-lg-3 text-center">
                <span class="badge badge-success">{{ year }} számlázott: <b>{{ parseInt(report.year[index_main]).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</b></span>
              </div>
              <div class="col-lg-3 text-center">
                <span class="badge badge-danger">Hátralék: <b>{{ parseInt(report.remaining[index_main]).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</b></span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-1">
                Január
              </div>
              <div class="col-lg-1">
                Február
              </div>
              <div class="col-lg-1">
                Március
              </div>
              <div class="col-lg-1">
                Április
              </div>
              <div class="col-lg-1">
                Május
              </div>
              <div class="col-lg-1">
                Június
              </div>
              <div class="col-lg-1">
                Július
              </div>
              <div class="col-lg-1">
                Augusztus
              </div>
              <div class="col-lg-1">
                Szeptember
              </div>
              <div class="col-lg-1">
                Október
              </div>
              <div class="col-lg-1">
                November
              </div>
              <div class="col-lg-1">
                December
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-lg-1" :class="[report.projects[index_main].archive ? 'bg-success-light': null ]" v-for="index in 12" :key="index">
                <small :id="'popover'+index+index_main+report.months[index_main][index]">{{ parseInt(report.months[index_main][index]).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</small>
                <b-popover :target="'popover'+index+index_main+report.months[index_main][index]" triggers="hover" placement="top">
                  <template #title>Havi számlák</template>
                  <p class="pb-0 mb-0 text-right" v-for="(price, index2) in report.months_array[index_main][index]" :key="index2"><span v-if="price != null"><b>{{report.months_array_names[index_main][index][index2]}}:</b> {{parseInt(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} Ft</span></p>
                </b-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="filter-modal" ref="filter-modal" title="Szűrés" hide-footer>
      <b-row>
        <b-col md="8">
          <b-form-group
            label="Monolit cég"
            label-for="monolit"
          >
            <b-form-select
              id="monolit"
              v-model="monolit"
              :options="monolitOptions"
            />
          </b-form-group>
          <b-form-group
            label="Projekt"
            label-for="project"
          >
            <b-form-select
              id="project"
              v-model="project"
              :options="monolitProjektOptions"
            />
          </b-form-group>
          <b-form-group
            label="Aktív / Lezárt projekt"
            label-for="active_archived"
          >
            <b-form-select
              id="active_archived"
              v-model="archived"
              :options="archivedOptions"
            />
          </b-form-group>
          <p class="mt-2 text-center">
            <button class="btn btn-primary" @click="getReport();$refs['filter-modal'].hide();">Szűrés a feltételekre</button>
          </p>
        </b-col>
      </b-row>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormGroup, BFormSelect, BPopover,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    BFormGroup,
    BFormSelect,
    BPopover,
  },
  data() {
    return {
      monolitOptions: [
        {
          value: '%',
          text: 'Összes',
        },
        {
          value: 'ME%',
          text: 'Monolit Épszer',
        },
        {
          value: 'MP%',
          text: 'Monolit Profi',
        },
      ],
      monolitProjektOptions: [
        {
          value: '%',
          text: 'Összes',
        },
      ],
      archivedOptions: [
        {
          value: '%',
          text: 'Összes',
        },
        {
          value: '0',
          text: 'Aktív',
        },
        {
          value: '1',
          text: 'Lezárt',
        },
      ],
      archived: '%',
      monolit: '%',
      project: '%',
      year: '',
      currentYear: '',
      report: [],
    }
  },
  created() {
    this.year = new Date().getFullYear()
    this.currentYear = new Date().getFullYear()
    this.getReport()
    this.getProject()
  },
  methods: {
    removeFilter(key) {
      switch (key) {
        case 'company':
          this.monolit = '%'
          this.getReport()
          break
        case 'project_id':
          this.project = '%'
          this.getReport()
          break
        case 'archived':
          this.archived = '%'
          this.getReport()
          break
        default:
          break
      }
    },
    yearMin() {
      // eslint-disable-next-line no-plusplus
      this.year--
      this.getReport()
    },
    yearPlus() {
      // eslint-disable-next-line no-plusplus
      this.year++
      this.getReport()
    },
    returnProjectbyID(id) {
      const project = this.monolitProjektOptions.find(project => project.value === id)
      return project.text
    },
    getReport() {
      axios({
        method: 'post',
        url: `report/${this.year}`,
        data: {
          monolit: this.monolit,
          project: this.project,
          archived: this.archived,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.report = response.data
      })
    },
    getProject() {
      axios({
        method: 'get',
        url: 'project/list-all',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.monolitProjektOptions = [
          {
            value: '%',
            text: 'Összes',
          },
        ]
        response.data.forEach(project => {
          this.monolitProjektOptions.push({
            value: project.id,
            text: project.name,
          })
        })
      })
    },
  },
}
</script>

<style scoped>
    .font-medium-5{
        cursor: pointer;
    }
    .scrollable-box{
        max-height: 75vh;
        overflow-y: auto;
    }
    hr{
      height: 5px;
      background: #3B4253;
      margin-top: 20px;
      margin-bottom: 20px;
    }
</style>
